<template>

  <b-card>
    <b-table
      ref="selectableTable"
      selectable
      select-mode="single"
      striped
      hover
      responsive
      class="position-relative"
      :items="items"
      primary-key="ProtID"
      :fields="fields"
      @row-selected="showEintrag"
    >
      <template #row-details="row">
        <b-card>
          <b-row>
            <b-col cols="12" md="6"
            >
              <b-form-group
                label="Anrede"
                label-for="anrede"
              >
                <b-form-input
                  v-model="row.item.Anrede"
                  trim
                  placeholder="Sehr geehrte..."
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
            <b-form-group
              label="Adresse Gericht (wenn Adresse leer)"
              label-for="gericht"
            >
              <v-select
                :disabled="row.item.Adresse !== ''"
                v-model="gerichtID"
                :options="gericht"
                :reduce="val => val.value"
                :clearable="false"
                @input="setGericht(row.item)"
              />
            </b-form-group>
          </b-col>
          </b-row>
          <b-row>
            <b-col
              md="6"
            >
              <b-form-group
                label="Ort, Datum"
                label-for="ortDatum"
              >
                <b-form-input
                  v-model="row.item.OrtDatum"
                  trim
                  placeholder="Ort, Datum..."
                />
              </b-form-group>
            </b-col>
            <b-col
              md="6"
            >
              <b-form-group
                label="Adresse"
                label-for="adresse"
              >
                <b-form-textarea
                  v-model="row.item.Adresse"
                  rows="2"
                  max-rows="4"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-overlay :show="isCalc" no-wrap></b-overlay>  <!-- obscures row content, otherwise enclose content to be obscured... -->
          <b-row>
            <b-col md="3">
              <!-- disabled when created from anywhere else, for instance Anfrage PK from Vorsorge... -->
              <b-button
                :disabled="currRow.Art === null"
                variant="primary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="create(row.item)"
              >
                Brief erstellen
              </b-button>
            </b-col>

            <b-col md="3">
              <b-button
                :disabled="!currRow.IstDatei"
                variant="primary"
                @click="show()"
              >
                Bearbeiten
              </b-button>
            </b-col>

            <!-- <b-col md="6 text-right mt-2">
              <b-button
                variant="primary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="speichern(row.item)"
              >
                Speichern
              </b-button>
            </b-col> -->

          </b-row>

        </b-card>
      </template>

    </b-table>

    <div v-show="false">
      <word-editor
        ref="we"
        dateiName="Brief"
        :doc-in="docIn"
        :modal-height="modalHeight"
        @getDocBlob="getDocBlob"
      />
    </div>

  </b-card>

</template>

<script>
import {
  BButton, BCard, BTable, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import db from '@/service/korrespondenz'
import wordEditor from '../../compo/WordEditor.vue'

export default {
  components: {
    BButton,
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    wordEditor,
    BOverlay,
    vSelect,
  },
  data() {
    return {
      file: null,
      // doc: null,
      docIn: null,
      blobOut: null,
      totalRows: 1000,
      fields: [
        { key: 'Beschrieb', label: 'Beschrieb' },
        { key: 'Datum', label: 'Datum' },
      ],
      /* eslint-disable global-require */
      items: null,
      currRow: null,
      selected: [],
      modalHeight: window.innerHeight - 300,
      isCalc: false,
      gericht: null,
      gerichtID: null,
    }
  },
  async created() {
    const r = await db.getDossierBriefe(this.$root.currDosID, this.$root.currDosArt, this.$g.user.Sprache)
    if (r != null && r.data !== null && r.data.length > 0) {
      for (let i = 0; i < r.data.length; i += 1) {
        r.data[i]._showDetails = false // eslint-disable-line
        r.data[i].isSavedOrHasData = r.data[i].DosBriefID !== null
      }
      this.items = r.data
      this.totalRows = this.items.length
    }
    const g = await db.getGerichtSel(this.$root.currDosID)
    if (g != null && g.data !== null && g.data.length > 0) this.gericht = g.data
  },
  methods: {
    async showEintrag(items) {
      if (items.length > 0) this.currRow = items[0]
      this.currRow._showDetails = !this.currRow._showDetails  // eslint-disable-line
      const r = await db.getDossierBriefSFDT(this.currRow.DosBriefID)
      if (r != null) {
        this.docIn = r.data
      }
    },
    // async speichern(item) {
    //   const r = await db.putDossierBrief(item)
    //   if (r != null) {
    //     this.currRow.isSavedOrHasData = true
    //   }
    //   db.postBriefSFDT(this.blobOut, item.DosBriefID) // eslint-disable-line

    //   this.$root.showToast('Änderungen gespeichert', 'Brief wurde erfolgreich gespeichert!')
    // },
    async setGericht(item) {
      const r = await db.getGericht(this.gerichtID)
      if (r.data.length > 0) item.Adresse = r.data[0].Adresse // eslint-disable-line
    },
    async create(item) { // Creates the document from the template when type/Art is set (specific document have to generated at the appropriate place in separis) and saves it directly...
      if (item.DosBriefID === null || await this.$root.msgBoxConfirm('Soll der bestehende Brief überschrieben werden?')) {
        this.isCalc = true
        // if (item.DosBriefID === null) {
        const r = await db.putDossierBrief(item)
        if (r != null) {
          // this.item.DosBriefID = r.data[0] // Throws exception...
          if (item.DosBriefID === null) {
            this.currRow.DosBriefID = r.data[0]
            this.currRow.isSavedOrHasData = true
            this.currRow.IstDatei = 1
            item.IstDatei = 1 // eslint-disable-line
          }
        }
        // }
        let rb = await db.createBrief(this.currRow.DosBriefID, this.$g.user.Sprache) // eslint-disable-line
        this.currRow.IstBrief = true

        // const rd = await db.getDossierBriefSFDT(item.DosBriefID)
        const rd = await db.getDossierBriefSFDT(this.currRow.DosBriefID)
        if (rd != null) {
          this.docIn = rd.data
        }

        this.$root.showToast(item.Name, 'Der Brief wurde erfolgreich erstellt!')
        this.isCalc = false
      }
    },
    async show() {
      this.$refs.we.show()
    },
    async getDocBlob(blob, doc) { // Save the document from word editor component...
      this.blobOut = blob
      this.docIn = doc
      await db.postBriefSFDT(this.blobOut, this.currRow.DosBriefID) // eslint-disable-line
      this.$root.showToast('Brief gespeichert', 'Brief wurde erfolgreich gespeichert!')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
